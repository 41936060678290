@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

body {
    background: rgb(17,24,39);
    color: white;
    font-family: 'Inter', sans-serif!important;
}

.header-nav button {
    color: rgba(209, 213, 219)!important;
}

.header-nav button:hover {
    color: white!important;
}

.sk-buttons-b > * {
    margin-top: 1vh!important;
}

.sk-info > * {
    margin-top: 1vh;
}

body, html {
    max-width: 100%;
    overflow-x: hidden;
    margin-bottom: 3vh;
}

@media (max-width: 1200px) {
    .g-mt {
        margin-top: 5vh!important;
    }
    .sk-container {
        display: block!important;
        margin-left: -16px!important;
    }
    .sk-buttons {
        width: 100%;
        max-width: 100%!important;
    }
    .sk-buttons-b {
        display: inline-flex;
    }
    .sk-buttons-b > * {
        margin-top: 0!important;
        margin-right: 1vh!important;
    }
    .sk-buttons-b i {
        margin-right: 0!important;
    }
    .sk-buttons-b span {
        display: none;
    }
    .sk-info {
        width: 100%;
        display: inline-flex;
    }
    .sk-info > * {
        width: 49.5%;
    }
    .sk-info > *:last-child {
        margin-left: 1%;
    }
    .main-item-container {
        margin-top: 1px!important;
    }
    .home-feature-two-image {
        width: 100%;
    }
    .grid-container {
        display: block!important;
    }
}

.no-text-ov {
    overflow: hidden;
    white-space: nowrap;
}

@font-face {
    font-family: "FortniteFont";
    src: url("../assets/BurbankBigRegular-Black.otf") format("opentype");
}

.item-legendary {
    box-sizing: border-box;
    border-width: 8px 0px 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    position: absolute;
    width: 103%;
    height: 2.75rem;
    bottom: -1.25rem;
    transform: rotate(-4deg);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    background: rgba(252, 156, 70, 0.95);
    border-color: rgb(248, 177, 84);
}

.item-epic {
    box-sizing: border-box;
    border-width: 8px 0px 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    position: absolute;
    width: 103%;
    height: 2.75rem;
    bottom: -1.25rem;
    transform: rotate(-4deg);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    background: rgba(235, 130, 253, 0.95);
    border-color: rgb(227, 129, 253);
}

.item-rare {
    box-sizing: border-box;
    border-width: 8px 0px 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    position: absolute;
    width: 103%;
    height: 2.75rem;
    bottom: -1.25rem;
    transform: rotate(-4deg);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    background: rgba(2, 231, 252, 0.95);
    border-color: rgb(2, 255, 255);
}

.item-uncommon {
    box-sizing: border-box;
    border-width: 8px 0px 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    position: absolute;
    width: 103%;
    height: 2.75rem;
    bottom: -1.25rem;
    transform: rotate(-4deg);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    background: rgba(132, 228, 11, 0.95);
    border-color: rgb(136, 228, 28);
}

.item-common {
    box-sizing: border-box;
    border-width: 8px 0px 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    position: absolute;
    width: 103%;
    height: 2.75rem;
    bottom: -1.25rem;
    transform: rotate(-4deg);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    background: rgba(190, 191, 195, 0.95);
    border-color: rgb(201, 207, 223);
}

.item-icon {
    box-sizing: border-box;
    border-width: 8px 0px 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    position: absolute;
    width: 103%;
    height: 2.75rem;
    bottom: -1.25rem;
    transform: rotate(-4deg);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    background: rgba(54, 216, 222, 0.90); 
    border-color: rgb(54, 216, 222);
}

.item-lava {
    box-sizing: border-box;
    border-width: 8px 0px 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    position: absolute;
    width: 103%;
    height: 2.75rem;
    bottom: -1.25rem;
    transform: rotate(-4deg);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    background: rgba(241, 162, 29, 0.90);
    border-color: rgb(241, 162, 29);
}

.item-frozen {
    box-sizing: border-box;
    border-width: 8px 0px 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    position: absolute;
    width: 103%;
    height: 2.75rem;
    bottom: -1.25rem;
    transform: rotate(-4deg);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    background: rgba(130, 211, 250, 0.90); 
    border-color: rgb(130, 211, 250);
}

.item-marvel {
    box-sizing: border-box;
    border-width: 8px 0px 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    position: absolute;
    width: 103%;
    height: 2.75rem;
    bottom: -1.25rem;
    transform: rotate(-4deg);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    background: rgba(223, 16, 22, 0.9);
    border-color: rgb(223, 16, 22);
}

.item-gaminglegends {
    box-sizing: border-box;
    border-width: 8px 0px 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    position: absolute;
    width: 103%;
    height: 2.75rem;
    bottom: -1.25rem;
    transform: rotate(-4deg);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    background: rgba(97, 98, 224, 0.9);
    border-color: rgb(97, 98, 224);
}

.item-starwars {
    box-sizing: border-box;
    border-width: 8px 0px 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    position: absolute;
    width: 103%;
    height: 2.75rem;
    bottom: -1.25rem;
    transform: rotate(-4deg);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    background: rgb(3, 36, 103, 0.90);
    border-color: rgb(3, 36, 103);
}

.item-dc {
    box-sizing: border-box;
    border-width: 8px 0px 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    position: absolute;
    width: 103%;
    height: 2.75rem;
    bottom: -1.25rem;
    transform: rotate(-4deg);
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    background:  rgba(40, 193, 225, 0.90);
    border-color: rgb(40, 193, 225);
}

.item-c {
    height: 195px;
    width: 150px;
}

.item-wrapper {
    box-sizing: border-box;
    border-width: 0px;
    border-style: solid;
    position: relative;
    width: 100%;
    height: 100%;
}

.item-container {
    box-sizing: border-box;
    border-width: 0px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 1);
    display: flex;
    position: relative;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: auto;
    background-position: center center;
    background-size: auto 100%;
    overflow: hidden;
}

.owner-verify {
    border: 1px solid #4f46e5;
    border-radius: 10px;
    padding-top: .65rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: .65rem;
}

.mr-2 {
    margin-right: 1vh;
}

.vbucks-component {
    margin-top: 3vh;
}

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 25vh;
}

.center-content {
    display: block;
    text-align: center;
}

*, ::after, ::before {
    --tw-ring-inset: var(--tw-empty, );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
}

.home-feature-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    border: 1px solid rgba(55, 65, 81, .5);
    border-radius: 8px;
}

.home-feature-two-image {
    border-radius: 15px;
}

.getStarted-container {
    border: 1px solid rgb(55 65 81);
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vh;
}

.getStarted-container > * {
    display: block!important;
}

.vbucks-image {
    width: 3vh;
}